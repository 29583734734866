import { useState } from "react"; 

const BagCostCalculator = () => {
    const [bagSize, setBagSize] = useState(null);  
    const [retailPrice, setRetailPrice] = useState(null); 
    const [cupsPerDay, setCupsPerDay] = useState(null); 
    const [pricePerCup, setPricePerCup] = useState(null); 
    const [costPerDay, setCostPerDay] = useState(null); 
    const [costPerMeal, setCostPerMeal] = useState(null); 
    const [costPerWeek, setCostPerWeek] = useState(null); 
    const [costPerMonth, setCostPerMonth] = useState(null); 
    const [costPerYear, setCostPerYear] = useState(null); 
    const [isReady, setIsReady] = useState(false); 
    let ppc; 

    const handleSubmit = (e) => {
        e.preventDefault(); 
        setIsReady(true); 

        ppc = (retailPrice / bagSize) * 0.5;
        setPricePerCup(ppc); 

        const x = ppc * cupsPerDay
        setCostPerDay(x); 

        const y = x/2
        setCostPerMeal(y); 

        const z = x * 7; 
        setCostPerWeek(z); 

        const a = z * 4; 
        setCostPerMonth(a); 

        const b = z * 52; 
        setCostPerYear(b); 
    }

    return(
        <div>
            <h2>Calculate Cost of Bag Over Time</h2>
            <form onSubmit={handleSubmit}>
                <label>Enter the size of the bag in pounds: </label>
                <input
                type="number"
                step="any"
                required
                value={bagSize}
                onChange={(e) => setBagSize(e.target.value)}
                />

                <label>Enter the retail price of the bag: </label>
                <input
                type="number"
                step="any"
                required
                value={retailPrice}
                onChange={(e) => setRetailPrice(e.target.value)}
                />

                <label>How many cups per day?</label>
                <input
                type="number"
                step="any"
                value={cupsPerDay}
                onChange={(e) => setCupsPerDay(e.target.value)}
                required
                />

                <button>Calculate prices</button>
            </form>

            <div>
                {isReady?
                    <div> 
                        <h2>Price per 8oz. cup:</h2>
                        <p>${pricePerCup.toFixed(2)}</p>

                        <h2>Cost per meal (assuming you feed 2x per day):</h2>
                        <p>${costPerMeal.toFixed(2)}</p>

                        <h2>Cost per day: </h2>
                        <p>${costPerDay.toFixed(2)}</p>

                        <h2>Cost per week: </h2>
                        <p>${costPerWeek.toFixed(2)}</p>

                        <h2>Cost per month: </h2>
                        <p>${costPerMonth.toFixed(2)}</p>

                        <h2>Cost per year: </h2>
                        <p>${costPerYear.toFixed(2)}</p>

                    </div>: <p></p> }
            </div>
        </div>
    ); 
}

export default BagCostCalculator; 
import { useState } from "react";
import { useNavigate } from 'react-router-dom'; 
import LineAndSuggestion from "./LineAndSuggestion";
import ExcelExport from "./ExcelExport";
import DatePicker from "react-datepicker"
// import styling for react-datepicker calendar
import 'react-datepicker/dist/react-datepicker.css'
import { useAuth } from "./contexts/AuthContext"; 

const Create = () => {
    const [title, setTitle] = useState(''); 
    const [vendor, setVendor] = useState('');
    const [startDate, setStartDate] = useState(''); 
    const [startStr, setStartStr] = useState(''); 
    const [endDate, setEndDate] = useState('');
    const [endStr, setEndStr] = useState(''); 
    const [data, setData] = useState(null); 
    const [isLoading, setIsLoading] = useState(false); 

    const { googleIdToken } = useAuth(); 

    const navigate = useNavigate(); 

    const fetchData = async () => {
        if (!document.cookie.includes("heartland-access-check")) {
            window.alert('You are not logged in with Heartland; if you logged in with Heartland earlier, your access key may have expired. Please click the "Authenticate with Heartland" link at the top of the page.'); 
            return null; 
        }

        try {
            setIsLoading(true);
            const response = await fetch('/api/pythontest2', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ googleIdToken }` 
                }, 
                body: JSON.stringify({ vendor, startStr, endStr })
            }); 
            
            if (response.ok) {
                const result = await response.json();  
                setData(result);   
            } else {
                console.log('An error occurred. Come back and fix with a more detailed error message'); 
                console.log(response); 
            }

        } catch (error) {
            console.log('error: ', error); 
        } finally {
            setIsLoading(false); // set isLoading to false when done 
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault(); 

        setIsLoading(true); 

        try {
            // call the fetchData function when user submits form 
            await fetchData(); 
        } catch (error) {
            console.error('Error: ', error); 
        } finally {
            setIsLoading(false); 
        }

    }

    const handleStartChange = (date) => {
        setStartDate(date); 

        const isoStartDateString = date.toISOString(); 
        // extract just month/day/yr from iso string
        const startDateString = isoStartDateString.slice(0,10); 
        setStartStr(startDateString); 
        //console.log(typeof startDateString); 
    } 

    const handleEndChange = (date) => {
        setEndDate(date); 

        const isoEndDateString = date.toISOString();  
        const endDateString = isoEndDateString.slice(0,10);
        setEndStr(endDateString); 
        //console.log(endDateString); 
    }

    return (
        <div className="create">
            <h2>Create a Query</h2>
            <form onSubmit={handleSubmit}>
                <label>Give your query a title:</label>
                <input 
                type="text"
                required
                value={title} 
                onChange={(e) => setTitle(e.target.value)}
                />

                <label>Select a vendor:</label>
                <select
                    required
                    value={vendor}
                    onChange={(e) => setVendor(e.target.value)}
                >
                    <option>Select an option</option>
                    <option value="100010">Nordic Naturals</option>
                    <option value="100008">Rawganics</option>
                    <option value="100035">Adored Beast</option>
                    <option value="100006">Liquid Health</option>
                    <option value="100124">NWC Naturals</option>
                    <option value="100026">Fluff & Tuff</option>
                    <option value="100018">New England Rawhide</option>
                    <option value="100015">Happy Dog of Cape Cod</option>
                    <option value="999999">TEST</option>
                </select>

                <label>Start date:</label>
                <DatePicker
                required
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={handleStartChange}
                />

                <label>End date</label>
                <DatePicker
                required
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={handleEndChange}
                />

                <br/>

                { !isLoading && <button className="create-query">Create query</button>}
                { isLoading && <button className="create-query" disabled>Creating query...</button>}
            </form>
            <br />

            { data ? (
                <ExcelExport excelData={data} fileName={`${title}`} />
            ) : (
                <p>Excel data export will be available after you create a query.</p>
            )}

            <br />

            {isLoading ? (
                <p>Loading...</p>
            ) : data ? (
                <LineAndSuggestion data={data} />
            ) : (
                <p>Waiting for query creation...</p>
            )}

        </div>
    );
}
 
export default Create;
import React, { useState, useEffect, useRef } from 'react'; 
import { Line } from 'react-chartjs-2'; 
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement } from 'chart.js'; 
Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement);

function LineAndSuggestion({ data }) {

  const [selectedItem, setSelectedItem] = useState(null); // dropdown selection item 

  const combinedChartData = []; // create array to contain individual charts 

  if (data && typeof data === 'object') {
    Object.keys(data).forEach((key) => { // "data" is an array of objects; each "key" represents the index value in the array (each index value corresponds to a unique object)
      const itemData = data[key]; // "data" is overall array, data[key] is a single object
      const filteredVals = Object.values(itemData).filter((value) => typeof value === 'number');
      const max = Math.max(...filteredVals);
      const tgtQty = max + Math.ceil(max * 0.1);
      const reorderPt = Math.ceil(max * 0.7);

      // keys to exclude so that only numbers are included on x-axis
      const excludedKeys = [
        'item.public_id',
        'item.description', 
        'item.custom@sub_category', 
        'item.custom@subclass',
        'item.custom@subclass1', 
        'item.custom@secondary_vendor'
      ]

      const chartData = {
        labels: Object.keys(itemData).filter((key) => !excludedKeys.includes(key)), // label x-axis with weeks (e.g., '23', '24', '25', etc.)
        datasets: [
          {
            label: itemData['item.description'], // title/label of chart
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: Object.values(itemData).filter((value) => typeof value === 'number'),
          },
        ],
      };

      combinedChartData.push({ max, tgtQty, reorderPt, chartData }); // add data for individual chart to array of charts 
    });
  }

  const handleInputChange = (e) => {
    const index = e.target.dataset.index;
    const value = e.target.value;
    const field = e.target.name;

    combinedChartData[index][field] = value;
  };

  const handleSubmitTgtQty = async (e) => {
    e.preventDefault(); 
    const value = e.target.value;
    console.log(value); 
  }

  // handle item selected from dropdown 
  const handleSelectItem = (e) => {
    setSelectedItem(e.target.value); 
  }

  return (
    <div>
      <h1>Sales per Week</h1>
      <br />
      <label htmlFor="itemDropdown">Select Item:</label>
      <select id="itemDropdown" onChange={handleSelectItem} value={selectedItem}>
        <option value={null}>Select an item to view graph</option>
        {combinedChartData.map((item, index) => (
          <option key={index} value={item.chartData.datasets[0].label}>
            {item.chartData.datasets[0].label}
          </option>
        ))}
      </select>
      <br />
      {selectedItem !== null && combinedChartData.length > 0 ? (
        combinedChartData
        .filter((item) => selectedItem === null || item.chartData.datasets[0].label === selectedItem)
        .map((item, index) => (
          <div key={index} className="graph-container">
            <h3>{item.chartData.datasets[0].label}</h3>
            <br />
            <p>Highest # sold in selected time period: {item.max}</p>
            <br />
            <p>*will likely try to add current target point and reorder qty here in future*</p>
            <br />
            <p> Suggested target quantity: {item.tgtQty}</p>
            <form onSubmit={handleSubmitTgtQty}>
              <input
                type="text"
                name="tgtQty"
                defaultValue={item.tgtQty}
              />
              <button>Set Target Quantity</button>
            </form>
            <p> Suggested reorder point: {item.reorderPt}</p>
            <input
              type="text"
              name="reorderPt"
              value={item.reorderPt}
              data-index={index}
              onChange={handleInputChange}
            />
            <br />
              <Line
                data={item.chartData}
                options={{
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: 'Number Sold per Week',
                      },
                      beginAtZero: true,
                    },
                    x: {
                      title: {
                        display: true,
                        text: 'Week of the Year',
                      },
                    },
                  },
                }}
              />
          </div>
        ))
      ) : null}

      {selectedItem === null && <p>Select an item to view the graph.</p>}
    </div>
  );
}

export default LineAndSuggestion;
import React, { useState, useEffect } from 'react'; 
import { Line } from 'react-chartjs-2'; 
// imports that may hopefully fix category is not a registered scale error 
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement } from 'chart.js'; 
Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement); 

function getRandomColor() {
    var randomColor = '#'+Math.floor(Math.random()*16777215).toString(16);
    return randomColor;
}

function LineChart({ data }) {

    const chartData = {
        labels: Object.keys(data).filter(key => key !== 'item.public_id'),
        datasets: [
            {
            label: data['item.public_id'],
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: Object.values(data).filter(value => typeof value === 'number')
            }
        ]
    }; 

    return (
        <div>
          <Line
            data={chartData}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      );
} 

function BackendData () {
    const [data, setData] = useState(null); 

    useEffect(() => {
        // make HTTP request to backend when this BackendData component mounts
        fetch('/api/pythontest2')
            .then((response) => response.json())
            .then((data) => setData(data[0]))
            .catch((error) => console.error('Error fetching data: ', error)); 
    }, []); 

    return (
        <div>
            <h1>Line Chart from Backend Data</h1>
            {data && <LineChart data={data} />}
        </div>
    )
}

export default BackendData; 
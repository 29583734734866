import React, { useEffect } from 'react'; 
import { useSearchParams } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom'; 

const HandleHeartlandRedirect = () => {
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate(); 

    useEffect(() => {
        const code = searchParams.get('code'); 

        if (code) {
            fetch('/api/exchange_code', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                }, 
                body: JSON.stringify({ code }),
            })
            .then(response => response.json())
            .then(data => {
                console.log('Authorization successful', data); 
                navigate("/create-new-query"); // redirect user to query creation page
            })
            .catch(error => {
                console.error('Error during authorization: ', error)
            })
        }
    }, [searchParams]);

    return <div>Processing...</div>
}

export default HandleHeartlandRedirect; 
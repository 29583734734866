import { useState, useEffect } from 'react'; 

// const useFetch = (url) => {
//     const [data, setData] = useState(null); 
//     const [isLoading, setIsLoading] = useState(true); 
//     const [error, setError] = useState(null); 

//     // this function runs for EVERY render (new render when web page starts, when something is deleted, etc.)
//     useEffect(() => {
//         const abortCont = new AbortController(); 

//         fetch(url, { signal: abortCont.signal })
//             .then(res => {
//                 if(!res.ok) {
//                     throw Error('could not fetch the data for that resource'); 
//                 }
//                 return res.json(); 
//             })
//             .then(data => {
//                 setData(data);
//                 setIsLoading(false); 
//                 setError(null); 
//             })
//             .catch(err => {
//                 if (err.name === 'AbortError') {
//                     console.log('fetch aborted'); 
//                 } else {
//                     setIsLoading(false); 
//                     setError(err.message);
//                 }
//             })
//         return () => abortCont.abort(); 
//     }, [url]); 

//     return { data, isLoading, error }
// }

const useFetch = (url) => {
     const [loading, setLoading] = useState(false); 
     const [error, setError] = useState(""); 

     const handleGoogle = async (response) => {
        setLoading(true); 
        fetch(url, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json", 
            }, 

            body: JSON.stringify({ credential: response.credential }), 
        })
            .then((res) => {
                setLoading(false); 

                return res.json(); 
            })
            .then((data) => {
                if (data?.user) {
                    localStorage.setItem("user", JSON.stringify(data?.user)); 
                    window.location.reload(); 
                }

                throw new Error(data?.message || data); 
            })
            .catch((error) => {
                setError(error?.message); 
            })
     }

    return { loading, error, handleGoogle }
}

export default useFetch; 
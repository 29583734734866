import React from 'react'; 

const HeartlandAuthRedirect = () => {
    const client_id = '911ef34211abc7c7714ea18ba8800e41'
    const scopes = 'reporting.modify%20reporting.read.metric.source_sales%20reporting.read.group.location%20reporting.read.group.item%20reporting.read.group.date%20inventory.values.read'
    const redirectUri = 'https%3A%2F%2Fapp.northpointpets.com%2Fcreate';  

    const authorizeUrl = `https://retail.heartland.us/oauth/authorize?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirectUri.toString()}`; 

    const redirectToHeartland = () => {
        window.open(authorizeUrl, '_blank')
    }; 

    return (
        <div>
            <div>
                <p>You are not currently authenticated with Heartland. Please click the button below. If you are prompted to enter the Company ID, enter "northpointpets."</p>
                <p>After authentication, you will be redirected to this application.</p>
            </div>
            <button onClick={redirectToHeartland}>Authorize with Heartland</button>
        </div>
    )
}

export default HeartlandAuthRedirect; 
import React, { useState } from 'react'; 
import { Card, Alert } from 'react-bootstrap'; 
import { useAuth } from './contexts/AuthContext';

const Login = () => {
    const { login } = useAuth(); 
    const [error, setError] = useState(''); 

    return (
        <>
            <Card>
                <Card.Body className="d-flex flex-column align-items-center">
                    <h2 className="text-center mb-4">Log In</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <img src="https://northpointpets.com/wp-content/uploads/2022/07/NPP_Emblem_FullColor_70.png" alt="Northpoint Logo" style={{marginBottom: '16px'}}/>
                    <button onClick={login} className="btn btn-primary">Sign in with Google</button>
                </Card.Body>
            </Card>
        </>
    )
}

export default Login; 
import React, { useContext, useState, useEffect } from 'react'; 
import { auth } from "../firebase"; 
import { useNavigate } from 'react-router-dom';

import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"; 
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getIdToken } from 'firebase/auth'; 
 
const AuthContext = React.createContext(); 

export function useAuth() {
    return useContext(AuthContext); 
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(); 
    const [loading, setLoading] = useState(true); 
    const [googleIdToken, setGoogleIdToken] = useState(); 
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate(); 

    provider.setCustomParameters({
        "hd": "northpointpets.com"
    }); 

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password) 
    }

    async function login() { 

        const result = await signInWithPopup(auth, provider); 

        // get the ID token
        const idToken = await getIdToken(result.user, true);
        // setGoogleIdToken(idToken);  

        try {
            const response = await fetch('/api/sessionLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
            });
    
            const data = await response.json();
            console.log("data is ", data); 
    
            if (data.error) {
                console.log(data.error);
            } else {
                // continue with sign-in process
                setCurrentUser(data.user); // set the user ("user" as defined by email returned by idToken from Firebase)
                navigate("/");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    function logout() {
        fetch('/api/sessionLogout', {
            method: 'POST', 
            credentials: 'include', 
        })
        .then(() => {
            auth.signOut(); 
            setCurrentUser(null); 
        })
        .catch(error => {
            console.error('Logout error: ', error); 
        }); 
    }

    function resetPassword (email) {
        return sendPasswordResetEmail(auth, email); 
    }

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch('/api/checkSession', {
                    method: 'GET', 
                    credentials: 'include'
                }); 
                const data = await response.json(); 
                if (data.loggedIn) {
                    setCurrentUser(data.user); 
                    console.log('The user is now logged in- this is the user: '); 
                } else {
                    setCurrentUser(null); 
                    console.log('The user was not logged in'); 
                }
            } catch (error) {
                console.error('Session check failed: ', error); 
            } finally {
                setLoading(false); 
            }
        }; 

        checkSession(); 

    }, []);

    const value = {
        currentUser, 
        googleIdToken,
        login,
        signup, 
        logout, 
        resetPassword
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}
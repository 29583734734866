import React, { useState } from "react"; 
import * as FileSaver from 'file-saver'; 
// import XLSX from 'sheetjs-style';  
import ExcelJS from 'exceljs'; 
import { useAuth } from "./contexts/AuthContext"; 

const ExcelExport = ({ excelData, fileName }) => {
    const [loading, setLoading] = useState(false); 

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const { googleIdToken } = useAuth();

    const fetchIDData = async (item) => {
        try {
            const response = await fetch('/api/get-qtyonhand-from-autogeneratedid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ googleIdToken }`
                },
                body: JSON.stringify({ public_id: item['item.public_id'] }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result)
                return result;
            } else {
                console.log('an error occured: ', response);
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const exportToExcel = async () => {
        setLoading(true); 

        if (!document.cookie.includes("heartland-access-check")) {
            window.alert('You are not logged in with Heartland; if you logged in with Heartland earlier, your access key may have expired. Please click the "Authenticate with Heartland" link at the top of the page.'); 
            setLoading(false); 
            return null; 
        }

        if (excelData) {
            // const dataWithQtyOnHand = await Promise.all(excelData.map(async (item) => {
            //     const numericVals = Object.values(item).filter(value => typeof value === 'number');
            //     const totalSold = numericVals.reduce((accumulator, currentVal) => accumulator + currentVal, 0);
            //     const maxVal = Math.max(...numericVals);
            //     const suggestedReorder = maxVal + Math.ceil(maxVal * 0.7);
            //     const quantityOnHand = await fetchIDData(item);
            //     const reorderMinusQOH = suggestedReorder - quantityOnHand; 

            //     // add max_value key to each item with calculated max
            //     return { ...item, 'Quantity on Hand': quantityOnHand, 'Total Sold': totalSold, 'Max Value': maxVal, 'Suggested Reorder Quantity': suggestedReorder, 'Suggested Order - QoH': reorderMinusQOH };
            // }));

            const createExcelWorkbook = async (jsonData) => {
                const workbook = new ExcelJS.Workbook(); 
                const worksheet = workbook.addWorksheet('Sheet 1');
                
                // column headers 
                const headers = Object.keys(jsonData[0]); 
                const headerRow = worksheet.addRow(headers); 

                // add data one line at a time
                jsonData.forEach((item) => {
                    const row = Object.values(item); 
                    worksheet.addRow(row); 
                })

                // thick border under top row
                headerRow.eachCell((cell) => {
                    cell.border = {
                        bottom: { style: 'medium'}
                    }
                })

                // automatically set width of columns 
                worksheet.columns.forEach(function (column, i) {
                    let maxLength = 0; 
                    column["eachCell"]({ includeEmpty: true }, function (cell) {
                        var columnLength = cell.value ? cell.value.toString().length : 10; 
                        if (columnLength > maxLength) {
                            maxLength = columnLength; 
                        }
                    })
                    column.width = maxLength < 10 ? 10 : maxLength; 
                })

                return workbook; 
            }

            const excelWorkbook = await createExcelWorkbook(excelData); 

            // save workbook to file 
            const buffer = await excelWorkbook.xlsx.writeBuffer(); 
            FileSaver.saveAs(new Blob([buffer], { type: fileType }), `${fileName}.xlsx`); 

            setLoading(false); 
        }

    }
    return (
        <div>
            <button className="export-excel" onClick={exportToExcel} disabled={loading}>
                {loading ? 'Loading...' : 'Export Data to Excel'}
            </button>
        </div>
    )
}


export default ExcelExport;
import { Link } from 'react-router-dom'; 

const Navbar = () => {
    return (
        <nav className="navbar">
            <h1>NorthPoint App</h1>

            <div className="links">
                <Link to="/">Home</Link>
                <Link to="/heartland-auth-redirect">Authenticate With Heartland</Link>
                <Link to="/contact">Contact</Link>
            </div>

        </nav>
    );
}
 
export default Navbar;